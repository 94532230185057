<template>
  <!-- 表单提交 -->
  <div class="write">
    <el-form ref="form" label-width="80px" class="write-form">
      <el-form-item label="好友名字">
        <el-input
          placeholder="请输入好友名字或暗号"
          clearable
          class="bor0"
          v-model="submitForm.title"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="留言内容">
        <el-input
          placeholder="请输入留言内容，不超过2000个字"
          class="bor0 content-box"
          type="textarea"
          maxlength="2000"
          show-word-limit
          v-model="submitForm.content"
          clearable
          resize="none"
        >
        </el-input>
      </el-form-item>
      <el-button class="btn-write" @click="addMsg">创建留言</el-button>
    </el-form>

    <!-- 跳转 -->
    <router-link to="/look" class="btn-to">
      <span>查看</span>
      <span>留言</span>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      submitForm: {
        title: "",
        content: "",
        createTime: "",
      },
      submitList: [],
    };
  },

  methods: {
    // 添加留言
    async addMsg() {
      if (
        this.submitForm.title.trim() === "" ||
        this.submitForm.content.trim() === ""
      )
        return alert("请检查暗号或者内容是否为空!");
      console.log(this.submitForm);
      const yy = new Date().getFullYear(); //年
      let mm = new Date().getMonth() + 1;
      mm = mm < 10 ? "0" + mm : mm; //月
      let dd = new Date().getDate(); //日
      dd = dd < 10 ? "0" + dd : dd;
      let HH = new Date().getHours(); //时
      HH = HH < 10 ? "0" + HH : HH;
      let MM = new Date().getMinutes(); //分
      MM = MM < 10 ? "0" + MM : MM;
      let ss = new Date().getSeconds(); //秒
      ss = ss < 10 ? "0" + ss : ss;
      const ymd = yy + "-" + mm + "-" + dd + " " + HH + ":" + MM + ":" + ss;

      this.submitForm.createTime = ymd;
      const res = await this.$http.post("/message/save", this.submitForm);

      if (res.data.code !== 200) {
        return this.$message.error("留言添加失败,请稍后再试!");
      }

      this.$message.success("留言成功!");
      this.submitForm = {};
      // 以下为localstore的写法
      // if (this.submitForm.title === "" || this.submitForm.content === "") {
      //   return alert("暗号或内容为空!");
      // }
      // const submitForm = {
      //   title: this.submitForm.title,
      //   content: this.submitForm.content,
      //   dates: ymd,
      // };
      // let messageList = JSON.parse(localStorage.getItem("message"));
      // let messageArr = [];
      // if (messageList) {
      //   messageList.push(submitForm);
      //   localStorage.setItem("message", JSON.stringify(messageList));
      // } else {
      //   messageArr.push(submitForm);
      //   localStorage.setItem("message", JSON.stringify(messageArr));
      // }
      // alert("留言成功!");
      // this.submitForm.title = "";
      // this.submitForm.content = "";
    },
  },
};
</script>
<style>
.el-form-item {
  border-bottom: 2px solid #4dbeb5;
}
.bor0 input,
.bor0 textarea {
  border: 0;
  resize: false;
}
.bor0 textarea {
  height: 150px;
}
.content-box {
  height: 200px;
}
</style>
<style scoped>
.write {
  box-sizing: border-box;
}
.write-form {
  box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  .write {
    width: 100%;
    margin-top: 10%;
  }
  .write-form {
    width: 100%;
    background: #fff;
    padding: 5% 5%;
  }
  /deep/.el-form-item__label {
    color: #4dbeb5;
  }
  .btn-write {
    color: #fff;
    background: #4dbeb5;
    width: 100%;
    height: 45px;
  }
  .btn-to {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgb(0 0 0 / 10%);
    background: #3aabb3;
    position: fixed;
    right: 30px;
    bottom: 50px;
    z-index: 10;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .btn-to span {
    display: block;
    color: #fff;
    font-size: 14px;
  }
}
</style>