<template>
  <div class="look">
    <!-- 表单 -->
    <el-form ref="form" label-width="80px" class="look-form">
      <el-form-item label="我的名字">
        <el-input
          placeholder="请输入你的名字或暗号"
          class="bor0"
          v-model="params.title"
        >
        </el-input>
      </el-form-item>
      <el-button class="btn-look" @click="lookMsg">查看留言</el-button>
    </el-form>
	<div class="total">留言共有:{{list.length || 0}}条</div>
    <!-- 列表 -->
    <ul v-if="list">
      <li @click="toNote">
        <el-card class="box-card top-card">
            点我给ta发条匿名短信
        </el-card>
      </li>
      <li v-for="(item, index) in list" :key="index" class="pd">
        <el-card class="box-card">
          <!--<div slot="header" class="clearfix" style="position:relative;">
             <span
              ><span style="color: #4dbeb5">暗号</span>:{{ item.title }}</span
            > 
            <img class="card-ding" src="@/assets/ding.png"></img>
          </div>-->
          <div style="white-space: pre-line"  v-html="item.content">
            <!-- {{ item.content }} -->
          </div>
          <div class="card-footer">
            <div class="block">
              <!-- <img src="@/assets/clock.png" alt=""> -->
              <span style="color:#ccc;">{{ item.createTime }}</span>
            </div>
          </div>
        </el-card>
      </li>
    </ul>

    <!-- 跳转 -->
    <router-link to="/write" class="btn-to">
      <span>创建</span>
      <span>留言</span>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {
        title: "",
      },
      list: [],
      links: '',
    };
  },
  created() {
    this.getNoteLinks();
  },
  methods: {
    async lookMsg() {
      this.list = [];
      const res = await this.$http.post("/message/list", this.params);
      if (res.data.code !== 200) {
        this.params.title = "";
        return this.$message.error("查询错误,请稍后再试!");
      }
      this.list = res.data.data;
      if (!this.list.length) {
        this.params.title = "";
        return this.$message.error("暗号错误，请重新输入!");
      }
    },

     //前往匿名短信
    toNote() {
      window.location.href = this.links;
    },

    //获取匿名短信链接
    getNoteLinks() {
      this.$http.get("/links/getLinks",{params:{
        title: '匿名短信'
      }}).then(res=>{
        this.links = res.data.data.links || '';
      })
    },
  },
};
</script>
<style>
.el-card {
  font-size: 14px;
}
.el-form-item {
  border-bottom: 2px solid #4dbeb5;
}
.bor0 input {
  border: 0;
}
</style>
<style scoped>
.total {
	text-align: left;
	padding: 10px 10px 10px 3%;
	font-size: 14px;
	color: #aaa;
}
ul {
  padding: 0 3% 3%;
}
.look {
  box-sizing: border-box;
}
.look-form {
  box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  .look {
    width: 100%;
    height: auto;
    margin-top: 10%;
  }
  .look-form {
    width: 100%;
    background: #fff;
    padding: 5% 5%;
  }
  .btn-look {
    color: #fff;
    background: #4dbeb5;
    width: 100%;
    height: 45px;
  }
  .top-card {
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    color: rgb(250, 99, 99);
  }
  .top-card >>> .el-card__body {
    text-align: center;
    padding: 15px 0;
  }
  /deep/.el-form-item__label {
    color: #4dbeb5;
  }
  /deep/.el-card__header {
    padding: 5px 10px;
    text-align: left;
    border: 0;
  }
  /deep/.el-card__body,
  .el-main {
    padding: 10px 10px 5px;
    text-align: left;
  }
  .pd {
    padding: 0 0 10px 0;
  }

  .card-ding {
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
    height: auto;
  }
  .card-footer {
    border-top: 1px solid #eee;
    margin-top: 8px;
  }
  .block {
    display: flex;
    padding-top: 8px;
  }
  .block img {
    height: 16px;
    width: 16px;
  }
  .btn-to {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgb(0 0 0 / 10%);
    background: #3aabb3;
    position: fixed;
    right: 30px;
    bottom: 50px;
    z-index: 10;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .btn-to span {
    display: block;
    color: #fff;
    font-size: 14px;
  }
}
</style>